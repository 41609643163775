// THIS PAGE IS HERE BECAUSE STORYBOOK IS NOT SETUP YET

import { actionToast, toast } from "src/components/01_atoms/toast";
import { Button } from "src/components/01_atoms/button";
import { Link } from "src/components/01_atoms/link";
import { Outlet } from "src/components/01_atoms/outlet";
import { Text } from "src/components/01_atoms/text";
import { HomeHeader } from "src/components/02_molecules/home-header";
import { ClaimsEmptyState } from "src/components/02_molecules/claims-empty-state";
import { RefundSidebar } from "src/components/03_organisms/refund-sidebar";
import { RadioCard } from "src/components/02_molecules/selectable-card";
import { CheckboxCard } from "src/components/02_molecules/selectable-card";
import { useState } from "react";
import { MobileDrawerLayout } from "src/components/04_layouts/mobile-drawer-layout";

export function DesignSystem() {
  const toastText = "This is a toast !";
  const toasts = [
    () => toast(toastText),
    () => toast.info(toastText),
    () => toast.success(toastText),
    () => toast.warning(toastText),
    () => toast.error(toastText),
    () => actionToast("You want more toasts ?", () => toast("MOOORE"), "More"),
    () => actionToast.info("You want more toasts ?", () => toast("MOOORE"), "More"),
    () => actionToast.success("You want more toasts ?", () => toast("MOOORE"), "More"),
    () => actionToast.warning("You want more toasts ?", () => toast("MOOORE"), "More"),
    () => actionToast.error("You want more toasts ?", () => toast("MOOORE"), "More"),
  ];
  let index = 0;
  const showToastMessage = () => {
    toasts[index]();
    index = (index + 1) % toasts.length;
  };

  const [selected, setSelected] = useState<"cat" | "dog">();
  const [checked, setChecked] = useState<number[]>([]);

  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Outlet vertical="spacing16px">
        <Text variant="display2-Bold">Hello</Text>
        <Link to="/login" color="accent" size="md" iconRight={"arrowRight"}></Link>
        <Link to="/login" color="accent" size="md">
          This is a link
        </Link>
        <div
          style={{
            position: "fixed",
            bottom: "1rem",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Button size="lg" fluid onClick={showToastMessage}>
            toast me !
          </Button>
        </div>
        <HomeHeader />
        <Button type="button" onClick={() => setIsVisible(true)}>
          Open mobile drawer
        </Button>
        <ClaimsEmptyState />
        <RefundSidebar />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            console.log("----submit----");
            console.log("selected: ", selected);
            console.log("checked: ", checked.join(", "));
          }}
        >
          <Outlet vertical="spacing16px" align="center">
            <Outlet horizontal="spacing16px">
              <RadioCard
                value="cat"
                name="pet"
                title="Cat"
                petType="cat"
                checked={selected === "cat"}
                onChange={() => setSelected("cat")}
              />
              <RadioCard
                value="dog"
                name="pet"
                title="Dog"
                description="This is a dog"
                petType="dog"
                alert="This is an alert"
                checked={selected === "dog"}
                onAlertClick={() => console.log("Alert clicked")}
                onChange={() => setSelected("dog")}
              />
            </Outlet>
            <Outlet horizontal="spacing16px">
              <CheckboxCard
                title="RadioCard"
                iconName="filledAccident"
                value="C"
                name="type"
                checked={checked.includes(1)}
                onChange={() =>
                  setChecked((prev) =>
                    prev.includes(1) ? prev.filter((i) => i !== 1) : [...prev, 1]
                  )
                }
              />
              <CheckboxCard
                title="RadioCard"
                iconName="filledMaladie"
                alert="hello world"
                value="D"
                name="type"
                checked={checked.includes(2)}
                onAlertClick={() => console.log("hello world")}
                onChange={() =>
                  setChecked((prev) =>
                    prev.includes(2) ? prev.filter((i) => i !== 2) : [...prev, 2]
                  )
                }
              />
            </Outlet>
            <Button variant="accent" size="lg" type="submit">
              Submit
            </Button>
          </Outlet>
        </form>
      </Outlet>
      <MobileDrawerLayout isVisible={isVisible} onClose={() => setIsVisible(false)} />
    </>
  );
}
